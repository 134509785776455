module.exports = [{
      plugin: require('/Users/batist/Projects/batistleman/batist-blog/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":false,"withWebp":true},
    },{
      plugin: require('/Users/batist/Projects/batistleman/batist-blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/batist/Projects/batistleman/batist-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-2317705-9"},
    },{
      plugin: require('/Users/batist/Projects/batistleman/batist-blog/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
